<template>
  <div>
    <section class="px-4 pt-4 flex items-center" @click="$router.go(-1)">
      <button>
        <svg
          viewBox="0 0 24 24"
          class="w-6 h-6 -ml-2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.828 12L15.778 16.95L14.364 18.364L7.99995 12L14.364 5.63599L15.778 7.04999L10.828 12Z"
            fill="black"
          />
        </svg>
      </button>
      <h1 ref="deliveryAddressRef" class="font-medium leading-tight truncate">
        {{ addressLine1 || "Specify your location" }}
      </h1>
    </section>

    <section class="px-4 pb-3 pt-2">
      <label class="flex items-center justify-between">
        <svg
          width="25"
          height="25"
          class="flex-none text-red-500"
          viewBox="0 0 18 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.364 15.364L9 21.728L2.636 15.364C1.37734 14.1053 0.520187 12.5017 0.172928 10.7558C-0.17433 9.01001 0.00390685 7.20041 0.685099 5.55588C1.36629 3.91136 2.51984 2.50575 3.99988 1.51683C5.47992 0.527899 7.21998 6.10352e-05 9 6.10352e-05C10.78 6.10352e-05 12.5201 0.527899 14.0001 1.51683C15.4802 2.50575 16.6337 3.91136 17.3149 5.55588C17.9961 7.20041 18.1743 9.01001 17.8271 10.7558C17.4798 12.5017 16.6227 14.1053 15.364 15.364ZM9 13C10.0609 13 11.0783 12.5786 11.8284 11.8284C12.5786 11.0783 13 10.0608 13 8.99998C13 7.93912 12.5786 6.9217 11.8284 6.17156C11.0783 5.42141 10.0609 4.99998 9 4.99998C7.93914 4.99998 6.92172 5.42141 6.17158 6.17156C5.42143 6.9217 5 7.93912 5 8.99998C5 10.0608 5.42143 11.0783 6.17158 11.8284C6.92172 12.5786 7.93914 13 9 13ZM9 11C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21072 10.0391 7 9.53042 7 8.99998C7 8.46955 7.21072 7.96084 7.58579 7.58577C7.96086 7.2107 8.46957 6.99998 9 6.99998C9.53044 6.99998 10.0391 7.2107 10.4142 7.58577C10.7893 7.96084 11 8.46955 11 8.99998C11 9.53042 10.7893 10.0391 10.4142 10.4142C10.0391 10.7893 9.53044 11 9 11Z"
            fill="currentColor"
          />
        </svg>

        <input
          ref="search"
          type="search"
          :placeholder="$t('search-your-location')"
          class="w-full border-gray-300 rounded-md px-3 mx-2 focus:ring-primary-500 focus:border-primary-500"
          @input="
            (e) => {
              hasQuery = !!e.target.value;
              searching = true;
              onQueryAutocomplete(e.target.value);
            }
          "
        />
        <button
          v-if="hasQuery"
          @click.prevent="
            hasQuery = false;
            $refs.search.value = '';
          "
          class="text-gray-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="fill-current w-5 h-5"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"
            />
          </svg>
        </button>
      </label>
    </section>

    <section v-if="!hasQuery" class="w-full text-gray-600">
      <div class="px-4 w-full bg-gray-100 flex items-center justify-between">
        <h1 class="py-1 text-sm text-gray-500 font-medium">
          {{ $t("saved-locations") }}
        </h1>

        <!-- <router-link :to="{ name: 'Address' }" class="text-sm text-blue-500 flex items-center font-medium">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
          </svg>
          <span>Manage</span>
        </router-link> -->
      </div>
      <div class="divide-y w-full">
        <div
          @click="useCurrentLocation"
          class="px-4 py-3 sm:py-5 hover:bg-gray-50 cursor-pointer"
        >
          <div class="flex items-center justify-between space-x-3 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="flex-none w-6 h-6 fill-current"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M13 1l.001 3.062A8.004 8.004 0 0 1 19.938 11H23v2l-3.062.001a8.004 8.004 0 0 1-6.937 6.937L13 23h-2v-3.062a8.004 8.004 0 0 1-6.938-6.937L1 13v-2h3.062A8.004 8.004 0 0 1 11 4.062V1h2zm-1 5a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm0 4a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
              />
            </svg>

            <div class="truncate w-full">
              <div class="flex items-center space-x-2">
                <h1 class="font-medium">{{ $t("use-my-current-location") }}</h1>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="deliveryLocation && deliveryLocation.lat"
          @click="onSelectAddress(deliveryLocation)"
          class="px-4 py-3 sm:py-5 hover:bg-gray-50 cursor-pointer"
        >
          <div class="flex items-start justify-between space-x-3 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="flex-none w-6 h-6 fill-current"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M16.95 15.95a7 7 0 1 0-9.9 0L12 20.9l4.95-4.95zM12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM13 11h4v2h-6V6h2v5z"
              />
            </svg>
            <div class="truncate w-full">
              <div class="flex items-center space-x-2">
                <h1 class="font-medium">{{ $t("last-used-location") }}</h1>
              </div>
              <h2 class="truncate text-sm text-gray-600">{{ addressLine1 }}</h2>
            </div>
          </div>
        </div>

        <div
          v-for="(each, i) in addresses"
          :key="i"
          @click="onSelectAddress(each)"
          class="px-4 py-3 sm:py-5 hover:bg-gray-50 cursor-pointer"
        >
          <div class="flex items-start justify-between space-x-3 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="flex-none w-6 h-6 fill-current"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M17.084 15.812a7 7 0 1 0-10.168 0A5.996 5.996 0 0 1 12 13a5.996 5.996 0 0 1 5.084 2.812zm-8.699 1.473L12 20.899l3.615-3.614a4 4 0 0 0-7.23 0zM12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
              />
            </svg>
            <div class="truncate w-full">
              <div class="flex items-center space-x-2">
                <h1 class="font-medium">{{ each.name }}</h1>
              </div>
              <h2 class="truncate text-sm text-gray-600">
                {{ each.addressLine1 }}
              </h2>

              <p v-if="each.remark" class="truncate text-sm text-gray-600 mt-1">
                {{ each.remark }}
              </p>
            </div>
            <!-- <svg
              v-if="each.unit"
              width="20"
              height="20"
              viewBox="0 0 18 18"
              fill="none"
              class="flex-none mt-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.243 16H18V18H0V13.757L9.9 3.85702L14.142 8.10102L6.242 16H6.243ZM11.313 2.44402L13.435 0.322022C13.6225 0.134551 13.8768 0.0292358 14.142 0.0292358C14.4072 0.0292358 14.6615 0.134551 14.849 0.322022L17.678 3.15102C17.8655 3.33855 17.9708 3.59286 17.9708 3.85802C17.9708 4.12319 17.8655 4.37749 17.678 4.56502L15.556 6.68602L11.314 2.44402H11.313Z"
                fill="black"
              />
            </svg> -->
          </div>
        </div>
        <div
          @click="$router.push({ name: 'Address' })"
          class="px-4 py-3 sm:py-5 hover:bg-gray-50 cursor-pointer"
        >
          <div class="flex items-center justify-between space-x-3 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="flex-none w-6 h-6 fill-current"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM11 10V7h2v3h3v2h-3v3h-2v-3H8v-2h3z"
              />
            </svg>
            <div class="truncate w-full">
              <div class="flex items-center space-x-2">
                <h1 class="font-medium">{{ $t("add-new-address") }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-else class="w-full text-gray-600">
      <h1
        class="bg-gray-100 w-full px-4 py-1 text-sm text-gray-500 font-medium"
      >
        {{ $t("search-results") }}
      </h1>
      <div v-if="results.length" class="divide-y w-full">
        <div
          v-for="(result, i) in results"
          :key="i"
          @click="onSelectSearchResult(result)"
          class="px-4 py-3 sm:py-5 hover:bg-gray-50 cursor-pointer"
        >
          <div class="flex items-start justify-between space-x-3 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="flex-none w-6 h-6 fill-current"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
              />
            </svg>
            <div class="truncate w-full">
              <h1 class="font-semibold mb-1">
                {{ result.structured_formatting.main_text }}
              </h1>
              <h2 class="truncate text-sm text-gray-600">
                {{ result.structured_formatting.secondary_text }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="!searching"
        class="p-8 space-y-5 flex flex-col items-center justify-center"
      >
        <img
          class="w-48"
          src="/img/illustrations/undraw_my_current_location_re_whmt.svg"
          alt=""
        />
      </div>
    </section>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { userStore } from "@/store/modules/user";
import cookies from "@/utils/cookies";
import { trxStore } from "@/store/modules/transaction";

export default {
  data() {
    return {
      results: [],
      searching: false,
      deliveryLocation: {},
      sessionToken: null,
      hasQuery: false
    };
  },
  created() {
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  },
  async activated() {
    this.searching = false;
    this.hasQuery = false;
    this.$refs.search.value = "";
    try {
      this.deliveryLocation = JSON.parse(
        (await cookies.get("delivery:location")) || "{}"
      );
      // this.$refs.search.value = this.location.addressLine1;
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    onSelectAddress(address) {
      const { source, dateIndex } = this.$route.params;
      if (source != "MEAL_PLAN") {
        cookies.set("delivery:location", JSON.stringify(address));
      } else {
        trxStore.SET_MEAL_PLAN_ADDR({ address, dateIndex });
      }
      this.$router.go(-1);
    },
    async useCurrentLocation() {
      if (navigator.geolocation) {
        const loading = await this.$ionic.loadingController.create({
          message: this.$t("loading")
        });
        await loading.present();

        const options = {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 60000
        };

        navigator.geolocation.getCurrentPosition(
          async (pos) => {
            console.log("pos", pos.coords);
            const { latitude: lat, longitude: lng } = pos.coords;
            // const { addressLine: addressLine1 } = await request.post(`v3/delivery/address-line`, {
            //   lat,
            //   lng
            // });

            await loading.dismiss();
            this.$router.push({ name: "Map", query: { lat, lng } });
          },
          async (e) => {
            // console.log("not supported", e.message);
            await loading.dismiss();
            await this.$ionic.alertController
              .create({
                header: "Oops",
                // subHeader: "Subtitle",
                message: e.message,
                buttons: ["OK"]
              })
              .then(async (alert) => {
                await alert.present();
              });
          },
          options
        );
      } else {
        await loading.dismiss();
        console.log("not supported");
      }
    },
    filterAddress(result, column) {
      const x = result.address_components.filter((each) =>
        each.types.includes(column)
      );
      if (x.length > 0) {
        return x[0].long_name.trim();
      }
      return "";
    },
    onSelectSearchResult(result) {
      var request = {
        placeId: result.place_id,
        fields: ["name", "address_component", "formatted_address", "geometry"]
      };

      var service = new google.maps.places.PlacesService(
        document.createElement(null)
      );
      service.getDetails(request, (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          const selectedPlace = this.setPlace(place, { unit: "" });
          // console.log("place", place,  place.geometry.location.lat(),  place.geometry.location.lng());
          // console.log("selectedPlace", selectedPlace)
          if (selectedPlace) {
            this.onBeforeConfirm(place);
          } else {
            this.$router.push({
              name: "Map",
              query: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
              }
            });
          }
        }
      });
    },
    setPlace(place, input) {
      const route = this.filterAddress(place, "route");
      const addressLine2 = "";
      let unit = this.filterAddress(place, "street_number");
      const postcode = this.filterAddress(place, "postal_code");
      const city = this.filterAddress(place, "locality");
      const state = this.filterAddress(place, "administrative_area_level_1")
        .replace("Negeri", "")
        .replace("Wilayah Persekutuan", "")
        .trim();
      const country = this.filterAddress(place, "country");
      // const pluscode = this.filterAddress(place, "plus_code");
      let formattedAddress = place.formatted_address;
      let addressLine1 = formattedAddress
        .split(", ")
        .map((each) => {
          return each
            .trim()
            .replace("Federal Territory of", "")
            .replace(/, /g, "")
            .trim();
        })
        .filter((each) => {
          let valid =
            (postcode.length > 0 ? !each.includes(postcode) : true) &&
            (city.length > 0 ? !each.includes(city) : true) &&
            (state.length > 0 ? !each.includes(state) : true) &&
            (country.length > 0 ? !each.includes(country) : true);
          return valid;
        });

      // console.log('ADD', test1, addressLine1)
      // console.log("postcode", postcode)
      // console.log("city", city, city.length)
      // console.log("state", state)
      // console.log("country", country)
      // console.log("route", route)
      // console.log("unit", unit)
      // console.log("pluscode", pluscode)
      // console.log("realunit", input.unit)

      if (addressLine1.length == 0 && route.length > 0) {
        //sometimes the street name contains the city name, and it won't pass the previous filter
        addressLine1.push(route);
      }
      // else if (addressLine1.length == 0 && pluscode.length > 0) {
      //   // handle pluscode addresses
      //   addressLine1.push(pluscode);
      // }

      if (addressLine1.length > 0) {
        if (input.unit.length > 0) {
          // remove original unit from address if there's any
          if (unit.length > 0) {
            addressLine1.shift();
          }
          // if user given unit, use given unit
          unit = input.unit;
        }

        let addrL1 = addressLine1.join(", ");
        //any place name that's higher than route level can be added in the beginning
        // if (!addrL1.includes(place.name)) addrL1 = place.name + " " + addrL1;
        const addr = {
          ...this.addr,
          addressLine1: addrL1,
          addressLine2,
          postcode,
          city,
          state,
          country,
          unit,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        this.invalidAddr = false;
        return addr;

        //whether it's a premis, establsihment, route .. as long as the unit is not defined esure they give
        //more details, especially if addressLine1 is only a street name(route), then the customer has to define
        //his building / unit
        // if (!street_number || !route) {
        //   this.remark = true;
        // }
        // const place_type = place.types.filter((t) => t === "establishment" || t === "premise");
        // if (place_type.length > 0) this.remark = true;
        //sometimes, even if google maps suggestion show that it's giving unit no, it doesn't pass it, that's why
        //we need to show the customer only capturedAddress
        // return true;
      } else {
        this.invalidAddr = true;
        return false;
      }
    },
    async onBeforeConfirm(place) {
      this.$ionic.alertController
        .create({
          header: this.$t("complete-your-delivery-address"),
          inputs: [
            {
              name: "unit",
              id: "unit",
              placeholder: this.$t("houses-units-lots")
            },
            {
              name: "remark",
              id: "remark",
              placeholder: this.$t("remarks-for-drivers-riders")
            }
          ],
          buttons: [
            {
              text: this.$t("cancel"),
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
              }
            },
            {
              text: this.$t("ok"),
              handler: (e) => {
                console.log("Confirm Ok", e);
                this.onConfirm(e, place);
              }
            }
          ]
        })
        .then((a) => a.present());
    },
    onConfirm(e, place) {
      // const trimmedRes = this.removePlusCode(response.results);
      console.log("place", place);
      const addr = this.setPlace(place, e);
      const latlng = { lat: +addr.lat, lng: +addr.lng };
      console.log("addr", addr);
      cookies.set(
        "delivery:location",
        JSON.stringify({
          ...addr,
          ...latlng,
          ...e,
          addressLine1: e.unit.trim()
            ? `${e.unit}, ${addr.addressLine1}`
            : addr.addressLine1
        })
      );
      this.$router.go(-1);
    },
    onQueryAutocomplete: debounce(async function(query) {
      if (query) {
        var autocompleteService = new google.maps.places.AutocompleteService();

        autocompleteService.getPlacePredictions(
          {
            input: query,
            sessionToken: this.sessionToken,
            language: "en",
            componentRestrictions: { country: ["my", "sg"] },
            fields: ["address_components", "geometry", "name"]
          },
          (predictions, status) => {
            if (
              status != google.maps.places.PlacesServiceStatus.OK ||
              !predictions
            ) {
              this.searching = false;
              console.log("status", status);
              return;
            }
            console.log("predictions", predictions);
            this.results = predictions;
            this.searching = false;
          }
        );
      } else {
        this.results = [];
        this.searching = false;
      }
    }, 1000)
  },
  computed: {
    addresses() {
      return userStore.profile?.addresses;
    },
    addressLine1() {
      return this.deliveryLocation?.addressLine1;
    }
  }
};
</script>

<style lang="scss">
.leaflet-control-locate a {
  display: flex !important;
  background: #fff;
  align-items: center;
  justify-content: center;
}
.leaflet-control-locate a:hover {
  background: #eee;
}
.leaflet-control-locate a.leaflet-bar-part div {
  background-position: left 0px top 0px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 17px;
  height: 17px;
}
.leaflet-control-locate a.leaflet-bar-part div.loading {
  background-image: url(/img/loading.gif);
}
.leaflet-control-locate a.leaflet-bar-part div.locate {
  background-image: url(https://icon-library.com/images/define-icon/define-icon-23.jpg);
}
</style>
