<template>
  <!-- <div class="relative" style="height: 100vh"> -->
  <div class="relative">
    <div
      class="
        marker
        absolute
        top-0
        left-0
        w-full
        h-full
        flex
        items-center
        justify-center
        bg-transparent
        z-10
        pointer-events-none
        select-none
      "
    >
      <img src="~@/assets/default/maps/marker-g.png" class="w-12 h-12 -mt-10" />
    </div>
    <div class="marker absolute top-0 left-0 w-full h-full bg-transparent z-10 pointer-events-none select-none">
      <div class="relative w-full h-full">
        <div class="absolute top-0 left-0 py-4 px-3">
          <button
            class="flex items-center pointer-events-auto select-auto bg-white border-2 border-gray-400 p-1 rounded-md"
            @click="$router.go(-1)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              />
            </svg>
            <!-- <span>Back</span> -->
          </button>
        </div>
        <div
          class="fixed sm:absolute bottom-0 left-0 right-0 p-5 w-full"
          style=""
          :disabled="searching"
          @click="onBeforeConfirm"
        >
          <button class="px-4 py-3 bg-primary rounded-lg w-full font-semibold pointer-events-auto select-auto theme">
            {{ $t("confirm-location") }}
          </button>
        </div>
      </div>
    </div>
    <div ref="map" class="z-0 sm:relative min-h-inner inset-0"></div>
  </div>
  <!-- </div> -->
</template>

<script>
import request from "@/utils/request";
import cookies from "@/utils/cookies";

export default {
  data() {
    return {
      searching: false,
      deliveryLocation: {},
      map: null,
      addr: {},
      invalidAddr: false
    };
  },
  created() {
    this.deliveryLocation = { lat: this.$route.query.lat, lng: this.$route.query.lng };
  },
  //avoid re-init
  mounted() {
    try {
      // this.getdeliveryLocation();
      this.$nextTick(() => {
        this.map = L.map(this.$refs.map, {
          doubleClickZoom: true,
          dragging: true,
          scrollWheelZoom: true,
          keyboard: false
        });
        this.map.zoomControl.setPosition("topright");

        // create control and add to map
        var lc = L.control
          .locate({
            icon: "locate",
            iconLoading: "loading",
            iconElementTag: "div",
            position: "topright",
            onLocationError: (e) => {
              // do nothing
              this.$ionic.alertController
                .create({
                  header: "Oops",
                  // subHeader: "Subtitle",
                  message: e.message,
                  buttons: ["OK"]
                })
                .then((alert) => {
                  alert.present();
                });
            }
          })
          .addTo(this.map);

        // request location update and set location
        // lc.start();
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        // var markerIcon = L.icon({
        //   iconUrl: require("@/assets/default/maps/marker-g.png"),
        //   iconSize: [50, 50], // size of the icon

        //   iconAnchor: [25, 50] // point of the icon which will correspond to marker's location
        // });
        // var marker = L.marker([this.$route.query.lat, this.$route.query.lng], {
        //   icon: markerIcon,
        //   zIndexOffset: 1000
        // }).addTo(this.map);
        // this.map.on("move", () => {
        //   marker.setLatLng(this.map.getCenter());
        //   // console.log(this.map.getCenter());
        // });

        this.map.on("dragstart", () => {
          this.searching = true;
        });

        // this.map.on("move", () => {
        //   marker.setLatLng(this.map.getCenter());
        //   // console.log(this.map.getCenter());
        // });
        //Dragend event of map for update marker position
        this.map.on("dragend", () => {
          var cnt = this.map.getCenter();
          // var position = marker.getLatLng();
          console.log(cnt);
          this.deliveryLocation = { lat: cnt.lat, lng: cnt.lng };
          this.searching = false;
        });

        this.$route.query.lat &&
          this.$route.query.lng &&
          this.map.setView([this.$route.query.lat, this.$route.query.lng], 18);
      });
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    filterAddress(result, column) {
      const x = result.address_components.filter((each) => each.types.includes(column));
      if (x.length > 0) {
        return x[0].long_name.trim();
      }
      return "";
    },
    async onBeforeConfirm() {
      this.$ionic.alertController
        .create({
          header: this.$t("complete-your-delivery-address"),
          inputs: [
            {
              name: "unit",
              id: "unit",
              placeholder: this.$t("houses-units-lots")
            },
            {
              name: "remark",
              id: "remark",
              placeholder: this.$t("remarks-for-drivers-riders")
            }
          ],
          buttons: [
            {
              text: this.$t("cancel"),
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
              }
            },
            {
              text: this.$t("ok"),
              handler: (e) => {
                console.log("Confirm Ok", e);
                this.onConfirm(e);
              }
            }
          ]
        })
        .then((a) => a.present());
    },
    async setPlace(place, input) {
      const street_number = this.filterAddress(place, "street_number");
      const route = this.filterAddress(place, "route");
      const addressLine2 = "";
      let unit = this.filterAddress(place, "street_number");
      const postcode = this.filterAddress(place, "postal_code");
      const city = this.filterAddress(place, "locality");
      const state = this.filterAddress(place, "administrative_area_level_1")
        .replace("Negeri", "")
        .replace("Wilayah Persekutuan", "")
        .trim();
      const country = this.filterAddress(place, "country");
      const pluscode = this.filterAddress(place, "plus_code");
      let formattedAddress = place.formatted_address;
      let addressLine1 = formattedAddress
        .split(", ")
        .map((each) => {
          return each
            .trim()
            .replace("Federal Territory of", "")
            .replace(/, /g, "")
            .trim();
        })
        .filter((each) => {
          let valid =
            (postcode.length > 0 ? !each.includes(postcode) : true) &&
            (city.length > 0 ? !each.includes(city) : true) &&
            (state.length > 0 ? !each.includes(state) : true) &&
            (country.length > 0 ? !each.includes(country) : true);
          return valid;
        });

      // console.log("ADD", addressLine1);
      // console.log("postcode", postcode);
      // console.log("city", city, city.length);
      // console.log("state", state);
      // console.log("country", country);
      // console.log("route", route);
      // console.log("unit", unit);
      // console.log("pluscode", pluscode);
      // console.log("realunit", input.unit);
      // console.log("latlng", this.deliveryLocation);

      if (addressLine1.length == 0 && route.length > 0) {
        //sometimes the street name contains the city name, and it won't pass the previosu filter
        addressLine1.push(route);
      } else if (addressLine1.length == 0 && pluscode.length > 0) {
        // handle pluscode addresses
        // addressLine1.push(pluscode);
        const { addressLine } = await request.post(`v3/delivery/address-line`, {
          lat: this.deliveryLocation.lat,
          lng: this.deliveryLocation.lng
        });
        addressLine1.push(addressLine);
      }

      if (addressLine1.length > 0) {
        if (input.unit.length > 0) {
          // remove original unit from address if there's any
          if (unit.length > 0) {
            addressLine1.shift();
          }
          // if user given unit, use given unit
          unit = input.unit;
        }

        let addrL1 = addressLine1.join(", ");
        //any place name that's higher than route level can be added in the beginning
        // if (!addrL1.includes(place.name)) addrL1 = place.name + " " + addrL1;
        const addr = {
          ...this.addr,
          addressLine1: addrL1,
          addressLine2,
          postcode,
          city,
          state,
          country,
          unit,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          street_number
        };
        this.invalidAddr = false;
        return addr;

        //whether it's a premis, establsihment, route .. as long as the unit is not defined esure they give
        //more details, especially if addressLine1 is only a street name(route), then the customer has to define
        //his building / unit
        // if (!street_number || !route) {
        //   this.remark = true;
        // }
        // const place_type = place.types.filter((t) => t === "establishment" || t === "premise");
        // if (place_type.length > 0) this.remark = true;
        //sometimes, even if google maps suggestion show that it's giving unit no, it doesn't pass it, that's why
        //we need to show the customer only capturedAddress
        // return true;
      } else {
        this.invalidAddr = true;
        return false;
      }
    },
    onConfirm(e) {
      const latlng = { lat: +this.deliveryLocation.lat, lng: +this.deliveryLocation.lng };

      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({
          location: latlng
          // componentRestrictions: {
          //   country: "MY",
          // }
        })
        .then(async (response) => {
          // const trimmedRes = this.removePlusCode(response.results);
          // console.log("resp", response, trimmedRes)
          if (response.results[0]) {
            const addr = await this.setPlace(response.results[0], e);
            console.log("addr", addr);
            if (addr) {
              cookies.set(
                "delivery:location",
                JSON.stringify({
                  ...addr,
                  ...latlng,
                  ...e,
                  addressLine1: e.unit.trim() ? `${e.unit}, ${addr.addressLine1}` : addr.addressLine1
                })
              );
              this.$router.go(-2);
            } else {
              this.$ionic.alertController
                .create({
                  header: this.$t("unable-to-find-your-address-please-try-again"),
                  buttons: ["OK"]
                })
                .then((a) => a.present());
            }
          } else {
            // manually key in
            this.$ionic.alertController
              .create({
                header: this.$t("unable-to-find-your-location-please-try-again"),
                buttons: ["OK"]
              })
              .then((a) => a.present());
            console.log("No results found");
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },
    removePlusCode(resp) {
      let res = resp.filter((loc) => {
        return !this.filterAddress(loc, "plus_code");
      });
      return res;
    }
  }
};
</script>

<style lang="scss" scoped>
.leaflet-control-locate a {
  display: flex !important;
  background: #fff;
  align-items: center;
  justify-content: center;
}
.leaflet-control-locate a:hover {
  background: #eee;
}
.leaflet-control-locate a.leaflet-bar-part div {
  background-position: left 0px top 0px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 17px;
  height: 17px;
}
.leaflet-control-locate a.leaflet-bar-part div.loading {
  background-image: url(~@/assets/default/maps/loading.gif);
}
.leaflet-control-locate a.leaflet-bar-part div.locate {
  background-image: url(~@/assets/default/maps/define-icon.jpeg);
}

.leaflet-top {
  top: 4.5rem !important;
}
@media (min-width: 640px) {
  .leaflet-top {
    top: 0 !important;
  }
}

.theme {
  color: var(--focused);
}
</style>
